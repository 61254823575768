var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-center"},[_c('b-button',{staticClass:"float-left",attrs:{"variant":"link"},on:{"click":function($event){_vm.hasHistory() ? _vm.$router.go(-1) : _vm.$router.push('/')}}},[_c('b-icon-arrow-left-circle',{attrs:{"scale":"1.5"}})],1),_c('router-link',{staticClass:"float-right",attrs:{"to":{ name: 'change_form_settings', params: { id: _vm.form.id } },"variant":"link"}},[_c('font-awesome-icon',{attrs:{"icon":"cog","size":"lg"}})],1),_c('h4',[_vm._v(" "+_vm._s(_vm.form.title)+" · "),(_vm.saving.length == 0)?_c('span',{staticClass:"text-success h6"},[_vm._v(" Last Saved on "+_vm._s(_vm.$moment(_vm.form.date_modified).format("LLLL"))+" ")]):_c('span',{staticClass:"text-primary h6"},[_c('b-spinner',{staticClass:"mr-2",attrs:{"small":""}}),_vm._v(" Saving ")],1)])],1),_c('b-alert',{staticClass:"mt-4",attrs:{"show":!_vm.fields.length,"variant":"secondary"}},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"info-circle"}}),_vm._v(" No fields to show. Click the \"Add Field\" button to get started! ")],1),_c('f2-form',{attrs:{"fields":_vm.fields,"prefill":_vm.prefill,"submit":false},on:{"onControlClick":_vm.onControlClick,"onFieldClick":_vm.onFieldClick},scopedSlots:_vm._u([{key:"edit-text",fn:function(ref){
var field = ref.field;
var index = ref.index;
return [_c('edit-field',{key:field.id,attrs:{"field":field,"index":index},on:{"save":_vm.saveField,"cancel":function($event){field.slotName=''},"onControlClick":_vm.onControlClick}}),_c('add-field',{on:{"add-field":_vm.addField}})]}},{key:"edit-input",fn:function(ref){
var field = ref.field;
var index = ref.index;
return [_c('edit-field',{key:field.id,attrs:{"field":field,"index":index},on:{"save":_vm.saveField,"cancel":function($event){field.slotName=''},"onControlClick":_vm.onControlClick}}),_c('add-field',{on:{"add-field":_vm.addField}})]}},{key:"edit-choice",fn:function(ref){
var field = ref.field;
var index = ref.index;
return [_c('edit-field',{key:field.id,attrs:{"field":field,"index":index},on:{"save":_vm.saveField,"cancel":function($event){field.slotName=''},"onControlClick":_vm.onControlClick},scopedSlots:_vm._u([{key:"custom-editor",fn:function(ref){
var field = ref.field;
return [_c('div',{staticClass:"mx-4"},[_c('h6',[_vm._v("Options")]),_vm._l((field.options),function(option,idx){return _c('b-input-group',{key:'choice-option' + idx,staticClass:"mt-2"},[_c('b-input',{model:{value:(option.text),callback:function ($$v) {_vm.$set(option, "text", $$v)},expression:"option.text"}}),_c('b-input',{model:{value:(option.value),callback:function ($$v) {_vm.$set(option, "value", $$v)},expression:"option.value"}}),_c('b-input-group-append',[_c('button',{staticClass:"sc-btn-primary ml-2",on:{"click":function($event){return field.options.splice(idx, 1)}}},[_c('font-awesome-icon',{attrs:{"icon":"trash"}})],1)])],1)}),_c('button',{staticClass:"sc-btn-primary pill mt-2",on:{"click":function($event){return field.options.push({ text: '', value: '' })}}},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"plus"}}),_vm._v(" Add Option ")],1)],2)]}}],null,true)}),_c('add-field',{on:{"add-field":_vm.addField}})]}}])}),_c('div',{staticClass:"my-4"},[_c('add-field',{on:{"add-field":_vm.addField}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }