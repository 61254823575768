<template>
    <div class="edit-field">
        <div v-if="Array.isArray(controls)" class="form-input-edit-controls">
            <button
                v-for="(control, idx) in controls"
                :key="idx"
                :class="control.variant"
                @click="$emit('onControlClick', control.command, field, index)"
            >
                <font-awesome-icon :icon="control.icon"></font-awesome-icon>
            </button>
        </div>
        <f2-form
            :fields="edit_fields[field.type].fields"
            :noNumbers="true"
            :submit="false"
            :prefill="localField"
            @inputChange="changeInput"
        >
        </f2-form>
        <f2-form
            :fields="edit_fields[field.type].options"
            :prefill="field.options"
            :noNumbers="true"
            @inputChange="changeInput2"
        ></f2-form>
        <slot name="custom-editor" :field="localField"></slot>
        <div class="text-center mt-3">
            <button
                class="sc-btn-primary pill mr-2"
                @click="$emit('save', localField)"
            >
                <font-awesome-icon icon="save" class="mr-2"></font-awesome-icon>
                Save
            </button>
            <button
                class="sc-btn-grey pill"
                @click="$emit('cancel')"
            >
                <font-awesome-icon icon="times" class="mr-2"></font-awesome-icon>
                Cancel
            </button>
            
        </div>
    </div>
</template>
<script>
import Form from "@/components/form/Form.vue"

export default {
    props: ["index", "field"],
    components: { "f2-form": Form},
    mounted() {
        this.edit_fields.textarea = this.edit_fields.input;
    },
    data() {
        return {
            localField : Object.assign({ settings: [(this.field.visible) ? "Visible" : "", (this.field.required) ? "Required" : ""]}, this.field),
            edit_fields: {
                text : {
                    fields: [
                        { label: "Text", type: "textarea", name: "label" },
                        {
                            label   : "Text Style", 
                            name    : "subtype",
                            type    : "select",
                            options : [
                                { text: "Normal",        value: "paragraph" },
                                { text: "Large Header",  value: "header1"   },
                                { text: "Medium Header", value: "header2"   },
                                { text: "Small Header",  value: "header3"   }
                            ]
                        }
                    ],
                    options: []
                },
                input: {
                    fields: [
                        { label: "Label",       type: "input", name: "label" },
                        { label: "Placeholder", type: "input", name: "placeholder" },
                        { label: "Settings", type: "choice", subtype:"switch", name: "settings",
                            options: ["Visible", "Required"], inline: true },
                        { label: "Subtype",
                        name : "subtype",
                        type : "select",
                        options: [
                            { text: "Text",     value: "text" },
                            { text: "Multiline Text", value: "multiline" },
                            { text: "Number",   value: "number" },
                            { text: "Date",     value: "date" },
                            { text: "Date and Time", value: "datetime-local" },
                            { text: "Time",     value: "time" },
                            { text: "Password / Secret Key", value: "password" }
                        ]}
                    ],
                    options: [
                        { label: "Help Text", type: "input", name: "helpText" }
                    ]
                },
                choice: {
                    fields: [
                        { label: "Label",       type: "input", name: "label" },
                        {
                            label: "Choice Type",
                            name : "subtype",
                            type : "choice",
                            subtype : "select",
                            options: [
                                { text: "Single Choice",   value: "radio"    },
                                { text: "Multiple Choice", value: "checkbox" },
                                { text: "Dropdown",        value: "select"   }
                            ]
                        },
                        { label: "Settings", type: "choice", subtype:"switch", name: "settings",
                            options: ["Visible", "Required"], inline: true }
                    ],
                    options: []
                },
                button: {
                    fields: [
                        {
                            label   : "Type", 
                            name    : "type",
                            type    : "select",
                            options : [
                                { text: "Input",     value: "text"     },
                                { text: "Textarea",  value: "textarea" },
                                { text: "Checkbox",  value: "check" }
                            ]
                        },
                        { label: "Label", type: "input", name: "label" }
                    ],
                    options: [
                        {
                            label: "On Click", 
                            name : "onclick",
                            type : "select",
                            options: [
                                { text: "Submit Form", value: "submit" }
                            ]
                        },
                        {
                            label   : "Redirect",
                            name    : "redirect",
                            type    : "input",
                            subtype : "text"
                        }
                    ]
                }
            },
            controls: [
                {
                    variant : "primary",
                    icon    : "clone",
                    command : "cloneField"
                },
                {
                    variant : "danger",
                    icon    : "trash-alt",
                    command : "removeField"
                }
            ]
        };
    },
    methods: {
        saveField(field) {
            var currentJobId = this.jobId++;
            this.saving.push(currentJobId);
            var localField     = Object.assign({}, field);
            localField.options = JSON.stringify(field.options || {});
            this.$api.post(
                `/api/forms/admin/${this.id}/saveField`,
                localField
            ).then(({ data }) => {
                console.log(data);
                Object.assign(field, data.field);
                //this.fields.push(response.data.field);
                setTimeout(() => {
                    this.saving.splice(this.saving.indexOf(currentJobId), 1);
                    this.last_saved = new Date();
                }, 100);
            });
        },
        changeInput(field, value) {
            console.log(this.localField, field, value);
            if (field.name == "settings" && field.type == "choice") {
                field.options.forEach((option) => {
                    if (typeof option == "string")
                        this.localField[option.toLowerCase()] = value.includes(option);
                    else
                        this.localField[option.text.toLowerCase()] = value.includes(option.value);
                });
            }
            this.localField[field.name || field.label.toLowerCase()] = value;
        },
        changeInput2(field, value) {
            if (typeof this.localField.options != "object") {
                this.localField.options = {};
            }
            this.localField.options[field.name || field.label.toLowerCase()] = value;
        },
    }
}
</script>
<style scoped>
    .edit-field {
        position: relative;
        background-color: #e6e6e6;
        border-top: 15px solid #8E9BD5;
        border-radius: 3px;
        list-style-type: none;
        padding: 30px 10px;
        margin: 30px 0px;
    }
    .form-input-edit-controls {
        position: absolute;
        top: 0px;
        right: 0px;
        background: none;
    }
    .form-input-edit-controls > button {
        background-color: transparent;
        border: 0.1px solid #d5d5d5;
        border-right: none;
        max-width: 75px;
        max-height: 75px;
        width: 50px;
    }
    .form-input-edit-controls > button.primary:hover:not(:disabled) {
        background: #007bff;
        color: #ffffff;
        transition-duration: 0.25s;
    }
    .form-input-edit-controls > button.danger:hover {
        background-color: #dc3545;
        color: #ffffff;
        transition-duration: 0.25s;
    }
</style>