<template>
    <div>
        <div class="text-center">
            <b-button
                variant="link"
                @click="hasHistory() ? $router.go(-1) : $router.push('/')"
                class="float-left"
            >
                <b-icon-arrow-left-circle scale="1.5"></b-icon-arrow-left-circle>
            </b-button>
            <router-link
                :to="{ name: 'change_form_settings', params: { id: form.id } }"
                variant="link"
                class="float-right"
            >
                <font-awesome-icon
                    icon="cog"
                    size="lg"
                ></font-awesome-icon>
            </router-link>
            <h4>
                {{ form.title }}
                &middot;
                <span v-if="saving.length == 0" class="text-success h6">
                    Last Saved on {{ $moment(form.date_modified).format("LLLL") }}
                </span>
                <span v-else class="text-primary h6">
                    <b-spinner small class="mr-2"></b-spinner> Saving
                </span>
            </h4>
        </div>
        <b-alert :show="!fields.length" variant="secondary" class="mt-4">
            <font-awesome-icon icon="info-circle" class="mr-2"></font-awesome-icon>
            No fields to show. Click the "Add Field" button to get started!
        </b-alert>
        <f2-form
            :fields="fields"
            :prefill="prefill"
            :submit="false"
            @onControlClick="onControlClick"
            @onFieldClick="onFieldClick"
        >
            <template #edit-text="{ field, index }">
                <edit-field
                    :key="field.id"
                    :field="field"
                    :index="index"
                    @save="saveField"
                    @cancel="field.slotName=''"
                    @onControlClick="onControlClick"
                ></edit-field>
                <add-field @add-field="addField"></add-field>
            </template>
            <template #edit-input="{ field, index }">
                <edit-field
                    :key="field.id"
                    :field="field"
                    :index="index"
                    @save="saveField"
                    @cancel="field.slotName=''"
                    @onControlClick="onControlClick"
                ></edit-field>
                <add-field @add-field="addField"></add-field>
            </template>
            <template #edit-choice="{ field, index }">
                <edit-field
                    :key="field.id"
                    :field="field"
                    :index="index"
                    @save="saveField"
                    @cancel="field.slotName=''"
                    @onControlClick="onControlClick"
                >
                    <template #custom-editor="{ field }">
                        <div class="mx-4">
                            <h6>Options</h6>
                            <b-input-group
                                v-for="(option, idx) in field.options"
                                :key="'choice-option' + idx"
                                class="mt-2"
                            >
                                <b-input v-model="option.text"></b-input>
                                <b-input v-model="option.value"></b-input>
                                <b-input-group-append>
                                    <button
                                        class="sc-btn-primary ml-2"
                                        @click="field.options.splice(idx, 1)"
                                    >
                                        <font-awesome-icon icon="trash"></font-awesome-icon>
                                    </button>
                                </b-input-group-append>
                            </b-input-group>
                            <button
                                class="sc-btn-primary pill mt-2"
                                @click="field.options.push({ text: '', value: '' })"
                            >
                                <font-awesome-icon icon="plus" class="mr-2"></font-awesome-icon>
                                Add Option
                            </button>
                        </div>
                    </template>
                </edit-field>
                <add-field @add-field="addField"></add-field>
            </template>
        </f2-form>
        <div class="my-4">
            <add-field @add-field="addField"></add-field>
        </div>
    </div>
</template>
<script>
import AddField  from './creator/AddField.vue';
import EditField from './creator/EditField.vue';
import F2Form    from "@/components/form/Form.vue"

export default {
    props: ["id", "form"],
    components: { F2Form, AddField, EditField },
    data () {
        return {
            field_id    : 0,
            jobId       : 0,
            last_saved  : null,
            loading     : false,
            prefill     : {},
            fields: [],
            responses: [],
            controls: [
                {
                    variant : "primary",
                    icon    : "pencil-alt",
                    command : "editField"
                },
                {
                    variant : "primary",
                    icon    : "arrow-up",
                    command : "moveFieldUp",
                    checkEnabled (self, field, index) {
                        return index > 0;
                    }
                },
                {
                    variant : "primary",
                    icon    : "arrow-down",
                    command : "moveFieldDown",
                    checkEnabled (self, field, index) {
                        return (self.fields.length - 1) > index;
                    }
                }
            ],
            selectedField: {},
            showEdit: false,
            saving: []
        };
    },
    mounted() {
        this.$emit("reset-breadcrumbs");
        if (this.form.fields)
        {
            this.fields = this.form.fields.map((field) => {
                var fieldCopy = Object.assign({}, field);
                fieldCopy.slotName = "";
                fieldCopy.controls = this.controls; 
                if (typeof fieldCopy.options == "string" || fieldCopy.options == null)
                    fieldCopy.options  = JSON.parse(fieldCopy.options || "{}");
                return fieldCopy;
            });
        }
        this.last_saved = this.form.date_modified;
    },
    methods: {
        hasHistory() {
            return window.history.length > 2;
        },
        clearTransient() {
            console.log("cleared transient.");
        },
        addField(field) {
            field._order   = this.fields.length + 1;
            field.slotName = "";
            field.controls = this.controls;

            this.fields.push(field);
            this.saveField(field);
        },
        saveField(modifiedField) {
            var currentJobId = this.jobId++;
            this.saving.push(currentJobId);
            var localField     = Object.assign({}, modifiedField);
            //localField.options = JSON.stringify(modifiedField.options || {});
            this.$api.post(
                `/api/forms/admin/${this.id}/saveField`,
                localField
            ).then(({ data }) => {
                console.log(data);
                //Object.assign(modifiedField, data.field);
                //this.fields.push(response.data.field);
                modifiedField.id = data.field.id;
                setTimeout(() => {
                    this.saving.splice(this.saving.indexOf(currentJobId), 1);
                    
                    var originalField = this.fields.find((field) => field.id == modifiedField.id);
                    Object.assign(originalField, modifiedField);
                    originalField.slotName = "";

                    this.last_saved = new Date();
                }, 100);
            });
        },
        removeField(targetIndex) {
            var field = this.fields.splice(targetIndex, 1)[0];
            console.log(field, "Field");
            var currentJobId = this.jobId++;
            this.saving.push(currentJobId);
            this.$api.post(`/api/forms/admin/${this.id}/removeField`, { id: field.id }).then((response) => {
                console.log(response.data);
                //this.fields.push(response.data.field);
                setTimeout(() => {
                    this.saving.splice(this.saving.indexOf(currentJobId), 1);
                    this.last_saved = new Date();
                }, 100);
            });
        },
        onControlClick(command, target, targetIndex) {
            console.log(command, target, targetIndex);
            if (command == "cloneField") {
                this.fields.push(Object.assign({}, target));
            }
            else if (command == "removeField") {
                this.removeField(targetIndex);
            }
            else if (command == "editField") {
                this.fields.forEach((field) => {
                    field.slotName = "";
                });
                this.selectedField = target;
                this.selectedField.edit = true;
                target.slotName = "edit-" + target.type;
                //this.showEdit = true;
            }
            else if (command == "moveFieldDown" && this.fields.length > 0 && targetIndex != this.fields.length) {
                this.fields.splice(targetIndex + 1, 0, this.fields.splice(targetIndex, 1)[0]);
                this.saveFieldOrder();
            }
            else if (command == "moveFieldUp" && this.fields.length > 0 && targetIndex != 0) {
                this.fields.splice(targetIndex - 1, 0, this.fields.splice(targetIndex, 1)[0]);
                this.saveFieldOrder();
            }
        },
        onFieldClick(target) {
            this.fields.forEach((field) => {
                field.slotName = "";
            });
            this.selectedField = target;
            this.selectedField.edit = true;
            target.slotName = "edit-" + target.type;
        },
        saveFieldOrder() {
            var currentJobId = this.jobId++;
            this.saving.push(currentJobId);
            this.$api.post(
                `/api/forms/admin/${this.id}/saveFieldOrder`,
                { order: this.fields.map((field) => `${field.id}`).join(";") }
            ).then(({ data }) => {
                //this.fields.push(response.data.field);
                if (!data.success)
                    this.$bvToast.toast(data.message, {
                        title           : (data.success) ? "Success!" : "Error!",
                        variant         : (data.success) ? "success"  : "danger",
                        autoHideDelay   : 5000,
                        appendToast     : true
                    });
                setTimeout(() => {
                    this.saving.splice(this.saving.indexOf(currentJobId), 1);
                    this.last_saved  = new Date();
                }, 100);
            });
        }
    }
}
</script>