<template>
    <div class="text-center mt-2">
        <button
            v-if="!show"
            class="sc-btn pill"
            @click="show = true"
        >
            <b-icon-plus-circle></b-icon-plus-circle> Add Field
        </button>
        <b-collapse v-model="show" class="mt-2">
            <button
                class="sc-btn pill mr-2"
                @click="addChoiceField()"
            >
                <font-awesome-icon icon="dot-circle"></font-awesome-icon> Choice
            </button>
            <button
                class="sc-btn pill mr-2"
                @click="addInputField()"
            >
                <font-awesome-icon icon="edit"></font-awesome-icon> Input
            </button>
            <button
                class="sc-btn pill mr-2"
                @click="addDateField()"
            >
                <font-awesome-icon icon="calendar-alt"></font-awesome-icon> Date
            </button>
            <button
                class="sc-btn pill mr-2"
                @click="addTextField()"
            >
                <font-awesome-icon icon="font"></font-awesome-icon> Text
            </button>
            <b-button-close
                @click="show = false"
            ></b-button-close>
        </b-collapse>
    </div>
</template>
<script>
export default {
    data() {
        return {
            show: false
        };
    },
    methods: {
        addChoiceField() {
            this.$emit(
                "add-field",
                {
                    type    : "choice",
                    subtype : "radio",
                    label   : "New Choice Field",
                    options : [{ text: "Option 1", value: "OptionValue1" }, { text: "Option 2", value: "OptionValue2" }]
                }
            );
            this.show = false;
        },
        addInputField() {
            this.$emit(
                "add-field",
                {
                    type    : "input",
                    subtype : "text",
                    label   : "New Input Field",
                    options : { helpText: "" }
                }
            );
            this.show = false;
        },
        addDateField() {
            this.$emit(
                "add-field",
                {
                    type    : "input",
                    subtype : "date",
                    label   : "New Date Field",
                    options : { helpText: "" }
                }
            );
            this.show = false;
        },
        addTextField() {
            this.$emit(
                "add-field",
                {
                    type    : "text",
                    subtype : "paragraph",
                    label   : "New Text Field"
                }
            );
            this.show = false;
        }
    }
}
</script>